import { useCallback, useEffect, useMemo, useState } from 'react';
import { isNaN, parseInt } from 'lodash-es';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { SKIP_RENDER } from 'constants/AppConstants';
import { FormFetchParam } from 'constants/FormFetchParam';
import { PageSize } from 'constants/PageSize';
import { useFetchCfpForms } from 'hooks/useFetchCfpForms';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { ListItem } from './ListItem';
import { TablePaginationActions } from './TablePaginationActions';
import { GlobalSearch } from './GlobalSearch';

export const View = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PageSize.TWENTY);
  const [searchCriteria, setSearchCriteria] = useState({
    [FormFetchParam.PAGE]: page,
    [FormFetchParam.PAGE_SIZE]: pageSize,
  });

  const { data: formConfigs, isLoading } = useFetchCfpForms({
    queryParams: searchCriteria,
  });

  const handleChangePageSize = (event) => {
    const nextNumber = parseInt(event.target.value);
    const nextPageSize = isNaN(nextNumber) ? PageSize.MAX : nextNumber;

    setPageSize(nextPageSize);
    setPage(0);
  };

  /**
   * Merges the existing filters' selection with the full text search
   * by applying the search
   *
   * This forces the sync mode to be ON, so that every change
   * is reflected in the URL
   */
  const searchBySearchTerm = useCallback(({ search }) => {
    setSearchCriteria((existingSearchCriteria) => ({
      ...existingSearchCriteria,
      [FormFetchParam.SEARCH]: search,
    }));
  }, []);

  const relevantForms = useMemo(
    () => formConfigs?.forms?.filter(({ client: { isDeleted } }) => !isDeleted),
    [formConfigs],
  );

  useEffect(
    () =>
      setSearchCriteria((existingSearchCriteria) => ({
        ...existingSearchCriteria,
        [FormFetchParam.PAGE]: page,
        [FormFetchParam.PAGE_SIZE]: pageSize,
      })),
    [page, pageSize],
  );

  return (
    <>
      {/* Loader */}
      <BackdropSpinner isOpen={isLoading} />

      {/* Search */}
      <GlobalSearch onChange={searchBySearchTerm} />

      {formConfigs ? (
        <Paper>
          <Box mt={6}>
            <Table>
              {/* Header */}
              <TableHead>
                <TableRow>
                  <TableCell>Client Name</TableCell>
                  <TableCell>Created date</TableCell>
                  <TableCell>Duplicate</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>

              {/* List */}
              <TableBody>
                {relevantForms.map((form) => (
                  <ListItem key={form.client.clientId} {...form} />
                ))}
              </TableBody>

              {/* Pagination */}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      PageSize.TEN,
                      PageSize.TWENTY,
                      { label: 'All', value: PageSize.MAX },
                    ]}
                    colSpan={5}
                    count={formConfigs?.totalNumber}
                    rowsPerPage={pageSize}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={setPage}
                    onRowsPerPageChange={handleChangePageSize}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Paper>
      ) : (
        SKIP_RENDER
      )}
    </>
  );
};
