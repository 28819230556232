import { useMutation, useQueryClient } from 'react-query';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  useNotification,
} from 'components/notifications';
import { QueryKeys } from 'QueryKeys';
import { useApi } from './useApi';

export const useUpdateCfpForm = (clientId) => {
  const { put } = useApi();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  return useMutation((payload) => put(`form/${clientId}`, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.FETCH_CFP_FORMS);

      showNotification(SUCCESS_NOTIFICATION, 'forms.update.success');
    },
    onError: () => showNotification(ERROR_NOTIFICATION, 'forms.update.error'),
  });
};
