import { Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CFPIcon } from './CFPIcon.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.spacing(9),
  },
  partner: {
    height: theme.spacing(5),
    minWidth: theme.spacing(25),
    width: theme.spacing(18),
  },
}));

export const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      className={classes.header}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      pl={isTabletUp ? 20 : 1}
      pr={isTabletUp ? 20 : 1}
    >
      <Box display="flex" justifyContent="center" width={200}>
        <CFPIcon />
      </Box>
    </Box>
  );
};
