import { useCallback, useState } from 'react';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDeleteCfpForm } from 'hooks/useDeleteCfpForm';
import { IntlDateTime } from 'intl/IntlDateTime';
import { getDuplicateById, getEditById } from 'constants/RoutePath';

export const ListItem = ({ client: { clientId, clientName, createdAt } }) => {
  const { push: navigate } = useHistory();
  const { mutate, isLoading } = useDeleteCfpForm({
    clientId,
  });

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const openConfirmationModal = () => setDeleteModalOpen(true);
  const closeModal = () => setDeleteModalOpen(false);
  const confirmDeletion = () => {
    mutate();

    closeModal();
  };

  const navigateToEdit = useCallback(
    () => navigate(getEditById(clientId)),
    [navigate, clientId],
  );

  const navigateToDuplicate = useCallback(
    () => navigate(getDuplicateById(clientId)),
    [navigate, clientId],
  );

  return (
    <>
      <TableRow hover>
        <TableCell>{clientName}</TableCell>
        <TableCell>
          <IntlDateTime date={createdAt} />
        </TableCell>

        <TableCell>
          <IconButton
            onClick={navigateToDuplicate}
            disabled={isLoading}
            aria-label="Duplicate config"
            size="large"
          >
            <FileCopyOutlinedIcon />
          </IconButton>
        </TableCell>

        <TableCell>
          <IconButton
            onClick={navigateToEdit}
            disabled={isLoading}
            aria-label="Edit config"
            size="large"
          >
            <EditIcon />
          </IconButton>
        </TableCell>

        <TableCell>
          <IconButton
            onClick={openConfirmationModal}
            disabled={isLoading}
            aria-label="Delete config"
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Deletion */}
      <Dialog open={isDeleteModalOpen} onClose={closeModal}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete <b>{clientName}</b> config?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={confirmDeletion}
            color="secondary"
            variant="contained"
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
