import { useQuery } from 'react-query';
import { QueryKeys } from 'QueryKeys';

const REFETCH_INTERVAL = 60 * 1000; // 1 minute in milliseconds

/**
 * Hook which periodically checks if the users token is still valid.
 *
 * NOTE: it might probably be a hack to use react-query for this,
 *       but then we don´t have to implement retry interval
 *       and refetch on window focus ourselves.
 */
const useTimedTokenRefresh = ({ onRefresh }) => {
  return useQuery(QueryKeys.TOKEN_REFRESH, () => onRefresh(), {
    refetchInterval: REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
    enabled: true, // Since we are logged in
  });
};

export default useTimedTokenRefresh;
