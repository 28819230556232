import { Auth } from 'aws-amplify';
import { useCallback } from 'react';
import { EMPTY_OBJECT, SKIP_RENDER } from 'constants/AppConstants';
import useTimedTokenRefresh from 'hooks/useTimedTokenRefresh';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { StorageKey } from 'constants/StorageKey';

export const TokenRefresh = () => {
  const [, setToken] = useLocalStorage(StorageKey.TOKEN_SESSION, EMPTY_OBJECT);

  const attemptToRefreshToken = useCallback(async () => {
    const cognitoUserSession = await Auth.currentSession();
    const cognitoIdToken = cognitoUserSession.getIdToken();
    const JWTToken = cognitoIdToken.getJwtToken();

    setToken(JWTToken);
  }, [setToken]);

  useTimedTokenRefresh({
    onRefresh: attemptToRefreshToken,
  });

  return SKIP_RENDER;
};
