import { useCallback, useMemo, useState } from 'react';
import { EMPTY_OBJECT, UNDEFINED } from 'constants/AppConstants';
import { Notification } from './Notification';
import { NotificationContext } from './NotificationContext';
import { SUCCESS_NOTIFICATION } from './NotificationType';

export const NotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState(UNDEFINED);
  const [type, setType] = useState(SUCCESS_NOTIFICATION);
  const [values, setValues] = useState(EMPTY_OBJECT);

  const showNotification = useCallback((nextType, nextText, nextValues) => {
    setType(nextType || SUCCESS_NOTIFICATION);
    setText(nextText);
    setValues(nextValues);

    setShow(true);
  }, []);
  const context = useMemo(() => ({ showNotification }), [showNotification]);

  return (
    <>
      <NotificationContext.Provider value={context}>
        {children}
      </NotificationContext.Provider>
      <Notification
        isOpen={show}
        setIsOpen={setShow}
        text={text}
        type={type}
        values={values}
      />
    </>
  );
};
