import { useLayoutEffect } from 'react';
import { useScrollToTop } from 'hooks/useScrollToTop';

export const useAutoScrollToTop = () => {
  const scrollToTop = useScrollToTop();

  useLayoutEffect(() => {
    scrollToTop();
  }, [scrollToTop]);
};
