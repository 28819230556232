import { useHistory, useParams } from 'react-router-dom';
import { isEmpty, merge } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';
import { useFetchCfpForm } from 'hooks/useFetchCfpForm';
import { useAddCfpForm } from 'hooks/useAddCfpForm';
import { UNDEFINED, EMPTY_STRING } from 'constants/AppConstants';
import { DefaultForm } from 'components/DefaultForm';
import * as RoutePath from 'constants/RoutePath';

const ERRONEOUS_HEADLINE = `We're sorry. We cannot identify the requested config.`;
const DUPLICATE_SUFFIX = `copy`;

export const Duplicate = () => {
  const { clientId } = useParams();
  const { push: navigate } = useHistory();

  const {
    isLoading: isFetchLoading,
    data,
    isError: isFetchFailed,
    isSuccess,
  } = useFetchCfpForm(clientId);

  const existingFormConfig = useMemo(() => {
    if (!data) {
      return UNDEFINED;
    }

    return merge({}, data, {
      client: {
        clientName: `${data?.client?.clientName} ${DUPLICATE_SUFFIX}`,
      },
    });
  }, [data]);

  const navigateToEditPage = useCallback(
    ({ client: { clientId: nextClientId } }) =>
      navigate(RoutePath.getEditById(nextClientId)),
    [navigate],
  );

  const { mutate: mutateByCreatingForm, isLoading: isMutateLoading } =
    useAddCfpForm({ onSuccess: navigateToEditPage });

  const isErroneous =
    isFetchFailed || (isSuccess && isEmpty(existingFormConfig));
  const isLoading = isFetchLoading || isMutateLoading;

  const defaultHeadline = (
    <>
      <Typography variant="h4">Duplicating</Typography>
      <Typography variant="h6">
        {existingFormConfig?.client?.clientName || EMPTY_STRING}
      </Typography>
    </>
  );

  return (
    <DefaultForm
      applyButtonLabel="Duplicate"
      headline={isErroneous ? ERRONEOUS_HEADLINE : defaultHeadline}
      initialValues={existingFormConfig}
      isLoading={isLoading}
      isDisabled={isErroneous}
      onApply={mutateByCreatingForm}
    />
  );
};
