import { Box, IconButton } from '@mui/material';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from '@mui/icons-material';

export const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleFirstPageButtonClick = () => onPageChange(0);
  const handleBackButtonClick = () => onPageChange(page - 1);
  const handleNextButtonClick = () => onPageChange(page + 1);
  const handleLastPageButtonClick = () =>
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box
      alignItems="center"
      display="flex"
      flexShrink={0}
      justifyContent="center"
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!page}
        size="large"
      >
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={!page} size="large">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large"
      >
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};
