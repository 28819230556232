import { isWebUri } from 'valid-url';
import unflatten from 'unflatten';
import { get, includes, isNil, set } from 'lodash-es';
import {
  EMPTY_ARRAY,
  EMPTY_SPACE,
  EMPTY_STRING,
  UNDEFINED,
} from 'constants/AppConstants';
import { FormField } from 'constants/FormField';

const HEX_REGEX = /^#([0-9a-f]{3}){1,2}$/i;

export const DEFAULT_FORM_VALUES = Object.freeze(
  unflatten({
    [FormField.ADDITIONAL_TEXT]: EMPTY_STRING,
    [FormField.BARCODE_MODE]: EMPTY_STRING,
    [FormField.CITY]: EMPTY_STRING,
    [FormField.CLIENT_NAME]: EMPTY_STRING,
    [FormField.COUNTRY_CODE]: EMPTY_STRING,
    [FormField.DEFAULT_COUNTRY_CODE]: EMPTY_STRING,
    [FormField.DO_PERSONALIZATION]: false,
    [FormField.EVENT_NAME]: EMPTY_STRING,
    [FormField.EXTERNAL_EVENT_ID]: EMPTY_STRING,
    [FormField.EXTERNAL_LOCATION_ID]: EMPTY_STRING,
    [FormField.FORM_FIELDS]: EMPTY_ARRAY,
    [FormField.GTC_URL]: EMPTY_STRING,
    [FormField.ISSUE_DCC]: false,
    [FormField.KEYPER_API_KEY]: EMPTY_STRING,
    [FormField.LOCATION_NAME]: EMPTY_STRING,
    [FormField.MAIN_COLOR]: EMPTY_STRING,
    [FormField.ORGANIZER_CITY]: EMPTY_STRING,
    [FormField.ORGANIZER_COUNTRY_CODE]: EMPTY_STRING,
    [FormField.ORGANIZER_EXTERNAL_ID]: EMPTY_STRING,
    [FormField.ORGANIZER_NAME]: EMPTY_STRING,
    [FormField.ORGANIZER_POSTAL_CODE]: EMPTY_STRING,
    [FormField.ORGANIZER_STREET]: EMPTY_STRING,
    [FormField.OVERVIEW_IMAGE_URL]: EMPTY_STRING,
    [FormField.PDF_HTML]: EMPTY_STRING,
    [FormField.POSTAL_CODE]: EMPTY_STRING,
    [FormField.PRIVACY_POLICY_URL]: EMPTY_STRING,
    [FormField.SELF_CHECK_IN_REFERENCE]: EMPTY_STRING,
    [FormField.SELF_CHECK_IN_URL]: EMPTY_STRING,
    [FormField.STREET]: EMPTY_STRING,
    [FormField.TARGET_LINK]: EMPTY_STRING,
    [FormField.TEST_TYPE]: EMPTY_STRING,
  }),
);

export const validateHex = (value) => {
  if (!HEX_REGEX.test(value)) {
    return 'The color is not valid. Expected in a format of #aaabbb or #aaa.';
  }

  return UNDEFINED;
};

export const validateURL = (value) => {
  if (!isWebUri(value)) {
    return 'The provided URL is not valid. Please provide a correct URL.';
  }

  return UNDEFINED;
};

export const validateURLIfDefined = (value) => {
  if (!value) {
    return UNDEFINED;
  }

  return validateURL(value);
};

export const validateNotWhitespaceOnly = (value) => {
  if (!value) {
    return UNDEFINED;
  }

  if (value.includes(EMPTY_SPACE)) {
    return 'The provided value is not valid. Please provide one without empty spaces.';
  }

  return UNDEFINED;
};

export const convertToBooleanIfApplies = (formValues, path) => {
  const maybeBoolean = get(formValues, path);

  if (isNil(maybeBoolean)) {
    return;
  }

  set(formValues, path, includes([true, 'true'], maybeBoolean));
};
