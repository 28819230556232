import { Controller } from 'react-hook-form';
import { Box, MenuItem, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormField } from 'constants/FormField';
import { validateNotWhitespaceOnly, validateURL } from 'components/modules';
import { EventConfigurationFragment } from './EventConfigurationFragment';
import { LocationConfigurationFragment } from './LocationConfigurationFragment';
import { OrganizerConfigurationFragment } from './OrganizerConfigurationFragment';

const BAR_CODE_MODE_OPTIONS = [
  {
    name: 'Please select ...',
    value: '',
  },
  { name: 'Key Mode', value: 'cfpKey' },
  { name: 'Hash Mode', value: 'cfpHash' },
];

export const TicketConfigurationFragment = ({ control, isDisabled }) => (
  <>
    <Controller
      control={control}
      name={FormField.KEYPER_API_KEY}
      rules={{
        required: 'keyper API key is required.',
        validate: validateNotWhitespaceOnly,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.KEYPER_API_KEY}
          InputLabelProps={{ shrink: true }}
          label="keyper API key"
          margin="dense"
          placeholder="e.g. 1234-5678-9012345-6789"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.BARCODE_MODE}
      rules={{
        required: 'Barcode mode is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          id={FormField.BARCODE_MODE}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ displayEmpty: true }}
          label="Barcode mode"
          margin="dense"
          select
          variant="outlined"
          size="small"
          {...field}
        >
          {BAR_CODE_MODE_OPTIONS.map(({ name, value }) => (
            <MenuItem key={name} value={value}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />

    <Controller
      control={control}
      name={FormField.SELF_CHECK_IN_URL}
      rules={{
        required: 'Self check-in URL is required.',
        validate: validateURL,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.SELF_CHECK_IN_URL}
          InputLabelProps={{ shrink: true }}
          label="Self check-in URL"
          margin="dense"
          placeholder="e.g. http://any-self-check-in.com/page"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.PDF_HTML}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.PDF_HTML}
          InputLabelProps={{ shrink: true }}
          label="PDF inline template (Handlebars)"
          margin="dense"
          placeholder="e.g. <h2>{{testExecution.testCode}}</div>"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Box py={2}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Event</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EventConfigurationFragment
            control={control}
            isDisabled={isDisabled}
          />
        </AccordionDetails>
      </Accordion>
    </Box>

    <Box py={2}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Location</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LocationConfigurationFragment
            control={control}
            isDisabled={isDisabled}
          />
        </AccordionDetails>
      </Accordion>
    </Box>

    <Box py={2}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Organizer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrganizerConfigurationFragment
            control={control}
            isDisabled={isDisabled}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  </>
);
