/**
 * All the existing route paths
 */
export const RoutePath = {
  ADD: '/add',
  DUPLICATE: '/duplicate/:clientId',
  EDIT: '/edit/:clientId',
  ROOT: '/',
  SIGN_OUT: '/sign-out',
  FALLBACK: '*',
};

export const getEditById = (clientId) =>
  RoutePath.EDIT.replace(':clientId', clientId);

export const getDuplicateById = (clientId) =>
  RoutePath.DUPLICATE.replace(':clientId', clientId);
