export const AvailableTests = [
  {
    name: 'COVID-19 Impfung - Comirnaty',
    id: '257986',
    test: {
      type: 'cov19.vaccine',
      manufacturer: 'comirnaty',
    },
  },
  {
    name: 'COVID-19 Impfung - Vaxzevria',
    id: '257987',
    test: {
      type: 'cov19.vaccine',
      manufacturer: 'vaxzevria',
    },
  },
  {
    name: 'COVID-19 Impfung - Moderna',
    id: '257988',
    test: {
      type: 'cov19.vaccine',
      manufacturer: 'moderna',
    },
  },
  {
    name: 'COVID-19 Antigen Schnelltest ',
    id: '259480',
    test: { type: 'cov19.antigen' },
  },
  {
    name: 'COVID-19 PCR-Test ',
    id: '259481',
    test: { type: 'cov19.pcr' },
  },
  {
    name: 'COVID-19 Antikörpertest',
    id: '259482',
    test: { type: 'cov19.antibody' },
  },
  {
    name: 'Namentliche Übermittlung',
    id: '272332',
    test: { type: 'unknown' },
  },
  {
    name: 'Pseudonymisierte Übermittlung',
    id: '272333',
    test: { type: 'unknown' },
  },
];
