import { Route, Switch } from 'react-router-dom';
import { Add, Duplicate, Edit, View as FormsList } from 'components';
import { Logout } from 'components/Logout';
import { RoutePath } from 'constants/RoutePath';
import { Navigation } from 'components/Navigation';
import { NotFound } from 'components/NotFound';
import { useAutoScrollToTop } from 'hooks/useAutoScrollToTop';
import { TokenRefresh } from 'TokenRefresh';

export const AuthenticatedApp = () => {
  useAutoScrollToTop();

  return (
    <>
      <Navigation />
      <TokenRefresh />
      <Switch>
        <Route path={RoutePath.ROOT} exact component={FormsList} />
        <Route path={RoutePath.ADD} component={Add} />
        <Route path={RoutePath.EDIT} component={Edit} />
        <Route path={RoutePath.DUPLICATE} component={Duplicate} />
        <Route path={RoutePath.SIGN_OUT} component={Logout} />
        <Route path={RoutePath.FALLBACK} component={NotFound} />
      </Switch>
    </>
  );
};

export default AuthenticatedApp;
