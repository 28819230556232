import styled from 'styled-components';
import { Paper } from '@mui/material';

export const PaperInput = styled(Paper)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
  justify-content: space-between;
  margin: 0 auto;
`;
