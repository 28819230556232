import { useMutation, useQueryClient } from 'react-query';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  useNotification,
} from 'components/notifications';
import { QueryKeys } from 'QueryKeys';
import { useApi } from './useApi';

export const useDeleteCfpForm = ({ clientId }) => {
  const { del } = useApi();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  return useMutation(() => del(`form/${clientId}`), {
    onSuccess: () => {
      showNotification(SUCCESS_NOTIFICATION, 'forms.delete.success');

      return queryClient.invalidateQueries(QueryKeys.FETCH_CFP_FORMS);
    },
    onError: () => showNotification(ERROR_NOTIFICATION, 'forms.delete.error'),
  });
};
