import { useQuery } from 'react-query';
import queryString from 'query-string';
import { ERROR_NOTIFICATION, useNotification } from 'components/notifications';
import { QueryKeys } from 'QueryKeys';
import { useApi } from './useApi';

export const useFetchCfpForms = ({ queryParams }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_CFP_FORMS, JSON.stringify(queryParams)],
    () =>
      get(
        queryString.stringifyUrl({
          url: 'form',
          query: queryParams,
        }),
      ),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'forms.fetch.error'),
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};
