import { useCallback, useState } from 'react';
import { Box, Grow, Paper, Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Add, ExitToApp, ListAlt } from '@mui/icons-material';
import { RoutePath } from 'constants/RoutePath';

export const Navigation = () => {
  const { pathname } = useLocation();
  const [route, setRoute] = useState(pathname);
  const { push: navigate } = useHistory();

  const onTabChange = useCallback(
    (_, value) => {
      setRoute(value);

      navigate(value);
    },
    [navigate],
  );

  return (
    <Box mb={3} width={1}>
      <Paper>
        <Box p={3}>
          <Grow in>
            <Tabs
              value={route}
              onChange={onTabChange}
              indicatorColor="primary"
              textColor="secondary"
              variant="fullWidth"
            >
              <Tab
                label="CFP form config list"
                icon={<ListAlt />}
                value={RoutePath.ROOT}
              />
              <Tab
                label="Add new config"
                icon={<Add />}
                value={RoutePath.ADD}
              />
              <Tab
                label="Sign out"
                icon={<ExitToApp />}
                value={RoutePath.SIGN_OUT}
              />
            </Tabs>
          </Grow>
        </Box>
      </Paper>
    </Box>
  );
};
