import { Controller } from 'react-hook-form';
import { Box, MenuItem, TextField } from '@mui/material';
import { FormField } from 'constants/FormField';
import { validateNotWhitespaceOnly } from 'components/modules';
import countries from 'intl/countries_en.json';

export const LocationConfigurationFragment = ({ control, isDisabled }) => (
  <Box display="flex" flexDirection="column" width={1}>
    <Controller
      control={control}
      name={FormField.EXTERNAL_LOCATION_ID}
      rules={{
        required: 'Location external ID is required.',
        validate: validateNotWhitespaceOnly,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.EXTERNAL_LOCATION_ID}
          InputLabelProps={{ shrink: true }}
          label="External location ID"
          margin="dense"
          placeholder="e.g. cfp_dev_loc"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.LOCATION_NAME}
      rules={{
        required: 'Location name is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.LOCATION_NAME}
          InputLabelProps={{ shrink: true }}
          label="Location name"
          margin="dense"
          placeholder="e.g. Covid-Free Pass"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.CITY}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.CITY}
          InputLabelProps={{ shrink: true }}
          label="City"
          margin="dense"
          placeholder="e.g. München"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.STREET}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.STREET}
          InputLabelProps={{ shrink: true }}
          label="Street"
          margin="dense"
          placeholder="e.g. Postgasse 7"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.POSTAL_CODE}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.POSTAL_CODE}
          InputLabelProps={{ shrink: true }}
          label="Postal Code"
          margin="dense"
          placeholder="4142"
          variant="outlined"
          size="small"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.COUNTRY_CODE}
      rules={{
        required: 'Location country code is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          id={FormField.COUNTRY_CODE}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ displayEmpty: true }}
          label="Location country code"
          margin="dense"
          select
          variant="outlined"
          size="small"
          {...field}
        >
          {countries.map(({ name, code }) => (
            <MenuItem key={name} value={code}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  </Box>
);
