import { I18n } from 'aws-amplify';
import { Translations } from '@aws-amplify/ui-components';

I18n.putVocabulariesForLanguage('de-AT', {
  [Translations.SIGN_IN_HEADER_TEXT]: 'Bei deinem C19T Support-Konto anmelden',
  [Translations.SIGN_IN_ACTION]: 'Anmelden',
  [Translations.USERNAME_LABEL]: 'E-Mail Adresse *',
  [Translations.USERNAME_PLACEHOLDER]: 'Gib deine E-Mail Adresse ein',
  [Translations.PASSWORD_LABEL]: 'Passwort *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Gib dein Passwort ein',
  [Translations.FORGOT_PASSWORD_TEXT]: 'Passwort vergessen?',
  [Translations.RESET_PASSWORD_TEXT]: 'Jetzt zurücksetzen',
  [Translations.RESET_YOUR_PASSWORD]: 'Setze dein Passwort zurück',
  [Translations.NO_ACCOUNT_TEXT]: ' ',
  [Translations.CREATE_ACCOUNT_TEXT]: ' ',
  [Translations.BACK_TO_SIGN_IN]: 'Zurück zur Anmeldung',
  [Translations.SEND_CODE]: 'Code senden',
  [Translations.CONFIRM_TOTP_CODE]: '2-Faktor-Authentifizierungs Code',
  [Translations.CODE_LABEL]: 'Authentifizierungs-Code',
  [Translations.CONFIRM]: 'Bestätigen',
  [Translations.CODE_PLACEHOLDER]: 'Code eingeben',
  [Translations.SIGN_OUT]: 'Abmelden',
});
