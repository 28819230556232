import { useQuery } from 'react-query';
import { ERROR_NOTIFICATION, useNotification } from 'components/notifications';
import { QueryKeys } from 'QueryKeys';
import { useApi } from './useApi';

export const useFetchCfpForm = (clientId) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_CFP_FORM, clientId],
    () => get(`form/${clientId}`),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'form.fetch.error'),
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );
};
