export const Colour = {
  background: '#f8fcfc',
  black: 'black',
  main: '#69E2CE',
  mainDark: '#146759',
  mainLight: '#b8f5dc',
  mainLightest: '#e5faf6',
  orange: '#FAB758',
  orangeBackground: '#fef6eb',
  red: '#F57A7A',
  text: '#444444',
  textMid: '#4A5568',
  textOrange: '#e48807',
};
