import { Box, Link } from '@mui/material';

export const Footer = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
      <Box p={1}>
        <Link
          color="textPrimary"
          href="https://www.keyper.io/imprint/"
          rel="noopener noreferrer"
          target="_blank"
          underline="hover"
        >
          Copyright © 2022 keyper GmbH - All rights reserved.
        </Link>
      </Box>
    </Box>
  );
};
