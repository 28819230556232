import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import StylesProvider from '@mui/styles/StylesProvider';
import { CssBaseline } from '@mui/material';
import { Colour } from './Colour';
import { GlobalStyle } from './GlobalStyle';
import { ScThemeProperties } from './ScThemeProperties';

const theme = {
  [ScThemeProperties.MAIN]: Colour.main,
  [ScThemeProperties.TEXT]: Colour.text,
  [ScThemeProperties.RED]: Colour.red,
  [ScThemeProperties.ORANGE]: Colour.orange,
  [ScThemeProperties.MAIN_LIGHT]: Colour.mainLight,
};

const muiTheme = createMuiTheme({
  typography: {
    h5: {
      fontWeight: '500',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.10)',
        },
        '&.Mui-active': {
          '& $text': {
            fill: 'white',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 500,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1em',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
  },
  palette: {
    text: {
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    mode: 'light',
    type: 'light',
    primary: {
      main: Colour.main,
    },
    secondary: {
      main: Colour.text,
    },
  },
});

export const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ScThemeProvider theme={theme}>
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ScThemeProvider>
  </StylesProvider>
);
