import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { isEqual } from 'lodash-es';
import { AuthenticatedApp } from 'AuthenticatedApp';
import { ThemeProvider } from 'theme/ThemeProvider';
import { IntlProvider } from 'intl/IntlProvider';
import { Layout } from 'components/Layout';
import { NotificationProvider } from 'components/notifications';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { StorageKey } from 'constants/StorageKey';
import { EMPTY_OBJECT } from 'constants/AppConstants';

const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    staleTime: Number.MAX_SAFE_INTEGER,
    queries: {
      retry: 0,
    },
  },
};

const App = () => {
  const [authState, setAuthState] = useState();
  const [, setToken] = useLocalStorage(StorageKey.TOKEN_SESSION, EMPTY_OBJECT);

  useEffect(
    () =>
      onAuthUIStateChange((nextAuthState, user) => {
        setToken(user?.signInUserSession?.getIdToken()?.getJwtToken());

        setAuthState(nextAuthState);
      }),
    [setToken],
  );

  return isEqual(AuthState.SignedIn, authState) ? (
    <IntlProvider>
      <ThemeProvider>
        <QueryClientProvider
          client={new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS)}
        >
          <NotificationProvider>
            <BrowserRouter>
              <Layout>
                <AuthenticatedApp />
              </Layout>
            </BrowserRouter>
          </NotificationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  ) : (
    <AmplifyAuthenticator />
  );
};

export default App;
