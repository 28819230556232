import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Language } from './Language';
import defaultMessages from './locales/en.json';

export const IntlProvider = ({ children }) => (
  <ReactIntlProvider
    defaultLocale={Language.EN}
    locale={Language.EN}
    messages={defaultMessages}
  >
    {children}
  </ReactIntlProvider>
);
