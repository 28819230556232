import { StrictMode } from 'react';
import Amplify from 'aws-amplify';
import { createRoot } from 'react-dom/client';
import { AmplifyConfiguration } from 'constants/AmplifyConfiguration';
import App from 'App';
import './intl/amplify/amplifyIntl';

Amplify.configure(AmplifyConfiguration);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
