import { useCallback, useMemo } from 'react';
import { EMPTY_OBJECT } from 'constants/AppConstants';
import { DEFAULT_HEADERS, del, get, post, put } from 'utils/api';
import { StorageKey } from 'constants/StorageKey';
import { useLocalStorage } from './useLocalStorage';

export const useApi = () => {
  const [token] = useLocalStorage(StorageKey.TOKEN_SESSION, EMPTY_OBJECT);

  const headers = useMemo(
    () =>
      token
        ? { ...DEFAULT_HEADERS, Authorization: `Bearer ${token}` }
        : DEFAULT_HEADERS,
    [token],
  );

  const onGet = (url, _headers = headers) => get(url, _headers);

  const onPost = (url, body, _headers = headers) => post(url, body, _headers);

  const onPut = (url, body, _headers = headers) => put(url, body, _headers);

  const onDelete = (url, _headers = headers) => del(url, _headers);

  return {
    post: useCallback(onPost, [headers]),
    get: useCallback(onGet, [headers]),
    put: useCallback(onPut, [headers]),
    del: useCallback(onDelete, [headers]),
  };
};
