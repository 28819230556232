import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const SCROLL_OPTS = {
  top: 0,
  left: 0,
  behavior: 'smooth',
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  return useCallback(() => {
    window?.scroll(SCROLL_OPTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
