import { AmplifySignOut } from '@aws-amplify/ui-react';
import { Box } from '@mui/material';

export const Logout = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    p={5}
    width={1}
  >
    <h3>Are you sure you want to log out?</h3>

    <Box width="50%">
      <AmplifySignOut />
    </Box>
  </Box>
);
