import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AvailableTests } from 'constants/AvailableTests';
import { FormField } from 'constants/FormField';

export const TestConfigurationFragment = ({ control, isDisabled }) => (
  <Box py={2}>
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Test</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width={1}>
          <Controller
            control={control}
            name={FormField.TEST_TYPE}
            render={({ field, fieldState: { error } }) => (
              <TextField
                disabled={isDisabled}
                error={!!error}
                fullWidth
                helperText={error?.message}
                id={FormField.TEST_TYPE}
                label="Test type"
                margin="dense"
                select
                variant="outlined"
                size="small"
                {...field}
              >
                {AvailableTests?.map(({ name, test: { type } }) => (
                  <MenuItem key={name} value={type}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            control={control}
            name={FormField.ISSUE_DCC}
            render={({ field }) => (
              <FormControl
                component="fieldset"
                fullWidth
                margin="dense"
                variant="filled"
              >
                <FormLabel component="legend">
                  <Typography variant="body2">Issue DCC (yes/no)</Typography>
                </FormLabel>

                <RadioGroup {...field} id={FormField.ISSUE_DCC}>
                  <FormControlLabel
                    control={<Radio size="small" />}
                    disabled={isDisabled}
                    label="Do issue DCC (yes)"
                    value="true"
                  />
                  <FormControlLabel
                    control={<Radio size="small" />}
                    disabled={isDisabled}
                    label="Do not issue DCC (no)"
                    value="false"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  </Box>
);
