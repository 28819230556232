export const EMPTY_OBJECT = Object.freeze({});
export const UNDEFINED = undefined;
export const EMPTY_STRING = '';
export const EMPTY_SPACE = ' ';
export const COMMA_CONCAT = ', ';
export const CONCAT_STRING = ', ';
export const SKIP_RENDER = null;
export const EMPTY_ARRAY = Object.freeze([]);
export const DATE_FORMAT = 'dd.MM.yyyy';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
