import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Typography } from '@mui/material';
import { useUpdateCfpForm } from 'hooks/useUpdateCfpForm';
import { DefaultForm } from 'components/DefaultForm';
import { EMPTY_STRING } from 'constants/AppConstants';
import { useFetchCfpForm } from 'hooks/useFetchCfpForm';

const ERRONEOUS_HEADLINE = `We're sorry. We cannot identify the requested config.`;

export const Edit = () => {
  const { clientId } = useParams();

  const {
    isLoading: isFetchLoading,
    data: existingFormConfig,
    isError: isFetchFailed,
    isSuccess,
  } = useFetchCfpForm(clientId);

  const { mutate: mutateByUpdatingForm, isLoading: isMutateLoading } =
    useUpdateCfpForm(clientId);

  const isErroneous =
    isFetchFailed || (isSuccess && isEmpty(existingFormConfig));
  const isLoading = isFetchLoading || isMutateLoading;
  const defaultHeadline = (
    <>
      <Typography variant="h4">Editing</Typography>
      <Typography variant="h6">
        {existingFormConfig?.client?.clientName || EMPTY_STRING}
      </Typography>
    </>
  );

  return (
    <DefaultForm
      applyButtonLabel="Update"
      headline={isErroneous ? ERRONEOUS_HEADLINE : defaultHeadline}
      initialValues={existingFormConfig}
      isLoading={isLoading}
      isDisabled={isErroneous}
      onApply={mutateByUpdatingForm}
    />
  );
};
